import React, { Fragment, useEffect, useState } from 'react'
import { LibraryBooks } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import DialogAttachmentsGallery from './dialog-attachments-gallery'

export default (props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  useEffect(() => {
    const open = localStorage.getItem('@dispute-open')
    if (open) {
      setIsDialogOpen(JSON.parse(open))
      localStorage.removeItem('@dispute-open')
    }
  }, [])

  return (
    <Fragment>
      <IconButton
        onClick={() => setIsDialogOpen(!props.isDialogOpen)}
        disabled={!props.attachments}
        className='btn-dispute'>
        <LibraryBooks />
      </IconButton>
      <DialogAttachmentsGallery
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        attachments={props.attachments}
      />
    </Fragment>
  )
}

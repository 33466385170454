import React, { Fragment, useEffect, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'

import { canPay } from '../../domain/document'
import Button from '../../component/button'
import Dialog from './dispute-dialog'
import { applyRowsForDisputeForm } from './__redux__/actions'
import { useTranslation } from 'react-i18next'

const disableButton = ({
  hasGateway,
  hasAllowedCurrency,
  hasCustomerSelected,
  getTotalAmount,
  isDisputeAlowed
}) =>
  !(
    isDisputeAlowed &&
    canPay({
      hasGateway,
      hasAllowedCurrency,
      hasCustomerSelected,
      totalAmount: Number(getTotalAmount.totalAmount)
    })
  )

const getAuth = (state) => get(state, 'auth.user')

export default (props) => {
  const user = useSelector(getAuth)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [disableStatus, setDisableStatus] = useState(true)
  const [selects, setSelects] = useState(props.rowsSelecteds)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleOpenDispute = async (e) => {
    e.preventDefault()
    e.stopPropagation()

    setIsDialogOpen(!isDialogOpen)
  }

  useEffect(() => {
    const statusPerfil =
      user.profile.includes('OLP_CUSTOMER') ||
      user.profile.includes('OLP_SUPPORT')

    statusPerfil
      ? setDisableStatus(disableButton(props))
      : setDisableStatus(true)

    if (props.rowsSelecteds.length > 0) {
      dispatch(applyRowsForDisputeForm(props.rowsSelecteds))
      const newSelects = props.rowsSelecteds
      setSelects(newSelects)
    }
  }, [props])

  return (
    <Fragment>
      <Button
        variant='contained'
        color='dispute'
        className={`${props.classes.button} btn-dispute`}
        disabled={disableStatus}
        onClick={handleOpenDispute}
        style={{
          marginRight: '5px'
        }}>
        {t('dispute-create-button')}
      </Button>
      <Dialog
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        user={user}
        selecteds={selects}
      />
    </Fragment>
  )
}

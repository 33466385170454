import React, { Fragment } from 'react'
import TableBodyMUI from '../../../component/table/table-body'
import TableCell from '../../../component/table/table-cell'
import TableRow from '../../../component/table/table-row'
import Tooltip from '../../../component/tooltip'
import { orderBy } from 'lodash'
import numeral from 'numeral'
import moment from 'moment'
import DisputeIcon from './dispute-icon'
import GetAttachmentsCell from './get-attachments-cell'
import PdfCell from './pdf-cell'
import getOverdueClass from './get-overdue-class'
import { isEmployee } from '../../../domain/security'
import renderHTML from 'react-render-html'
import { escape } from 'html-escaper'

const TableBody = (props) => {
  return (
    <TableBodyMUI>
      {orderBy(props.rows, props.orderBy, props.order).map((row, index) => {
        const labelId = `enhanced-table-checkbox-${index}`

        return (
          <Fragment>
            <TableRow hover role='checkbox' tabIndex={-1} key={row.id}>
              <TableCell data-label-thead='Customer' id={labelId}>
                {row.customerCode}
              </TableCell>
              <TableCell align='left' data-label-thead='Invoice'>
                {row.number}
              </TableCell>
              <TableCell align='left' data-label-thead='Total'>
                {numeral(row.totalAmount).format('0,0.00')}
              </TableCell>
              <TableCell align='left' data-label-thead='Currency'>
                {row.issuedCurrency}
              </TableCell>
              <TableCell
                align='left'
                data-label-thead='Invoice Due Date'
                className={getOverdueClass(row)}>
                {moment(row.dueDate).format('YYYY-MM-DD')}
              </TableCell>
              <TableCell align='left' data-label-thead='Dispute Case #'>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                  {row.caseNumber}
                  {<DisputeIcon />}
                </div>
              </TableCell>
              <TableCell align='left' data-label-thead='Dispute Date'>
                {row.createdAt
                  ? moment(row.createdAt).format('YYYY-MM-DD')
                  : null}
              </TableCell>
              <TableCell align='left' data-label-thead='Dispute Reason'>
                {(row.ratingDisputeReason || row.disputeReason) && (
                  <Tooltip
                    textTooltip={renderHTML(
                      `<p>${
                        row.ratingDisputeReason ? row.ratingDisputeReason : ''
                      }${
                        row.disputeReason && row.ratingDisputeReason
                          ? '<br/>'
                          : ''
                      }${
                        row.disputeReason ? escape(row.disputeReason) : ''
                      }</p>`.toString()
                    )}
                    color='#ffffff'
                    position='top'
                    backgroundColor='rgba(0,0,0,0.75)'>
                    <span>
                      <i
                        style={{ cursor: 'pointer' }}
                        className='material-icons i-ico-more-color'>
                        more
                      </i>
                    </span>
                  </Tooltip>
                )}
              </TableCell>
              <TableCell align='left' data-label-thead='Dispute'>
                {row.disposition}
              </TableCell>
              <TableCell align='left' data-label-thead='Disposition Reason'>
                {row.dispositionReason && (
                  <Tooltip
                    textTooltip={row.dispositionReason}
                    color='#ffffff'
                    position='top'
                    backgroundColor='rgba(0,0,0,0.75)'>
                    <span>
                      <i
                        style={{ cursor: 'pointer' }}
                        className='material-icons i-ico-more-color'>
                        more
                      </i>
                    </span>
                  </Tooltip>
                )}
              </TableCell>
              <TableCell>
                {row.dispositionDate
                  ? moment(row.dispositionDate).format('YYYY-MM-DD')
                  : null}
              </TableCell>
              {isEmployee(props.user) ? (
                <TableCell align='left' data-label-thead='Case Owner'>
                  {row.disputeCaseOwner ? row.disputeCaseOwner : row.caseOwner}
                </TableCell>
              ) : null}
              {row.creditNumber &&
              [
                'Disposition Provided - Full Credit Applied',
                'Disposition Provided - Partial Credit Applied',
                'Credit Approved',
                'Credit Partially Approved'
              ].includes(row.disposition) ? (
                <PdfCell row={row} />
              ) : (
                <TableCell align='left' data-label-thead='Credit Note'>
                  {null}
                </TableCell>
              )}
              <TableCell align='left' data-label-thead='Attachments'>
                <GetAttachmentsCell attachments={row.attachments} />
              </TableCell>
              <TableCell align='left' data-label-thead='Attachments'>
                <GetAttachmentsCell
                  attachments={
                    row.disputeAttachment ? [row.disputeAttachment] : null
                  }
                />
              </TableCell>
            </TableRow>
          </Fragment>
        )
      })}
    </TableBodyMUI>
  )
}

export default TableBody
